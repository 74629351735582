<template>
  <ion-page>
    <ion-content>
      <ion-grid>
        <ion-row class="ion-align-items-center mb-4 border-bottom">
          <ion-col>
            <ion-label class="fw-600 fs-3 text-gray-700 one-line-text">
              {{ $t('update') }} {{ cartItem?.product_name }}
            </ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-icon
              class="text-gray-500 fs-5"
              @click="$emit('closeModal')"
              size="medium"
              :icon="closeCircleOutline"
            ></ion-icon>
          </ion-col>
        </ion-row>

        <ion-row class="ion-align-items-center mx-2">
          <ion-col size="2" class="px-0 py-0">
            <ion-toggle ref="toggleRef" :disabled="blockedSample" v-model="isSampleProduct"></ion-toggle>
          </ion-col>
          <ion-col size="10">
            <ion-label class="fw-500 fs-2" :class="blockedSample ? 'text-gray-500' : 'text-gray-800'"
              >{{ $t('set_sample_product') }}
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-button
          @click="onApply()"
          fill="solid"
          expand="block"
          :color="!isEnabledApplyAction ? 'grey' : 'primary'"
          :disabled="!isEnabledApplyAction"
          class="rounded-md my-2 w-90 sticky-bottom-custom"
        >
          <ion-label class="text-capitalize"> {{ $t('apply') }} </ion-label>
        </ion-button>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { useSampleProduct } from '@/usecases/cart';
import { closeCircleOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CartItemMoreAction',
  props: {
    cartItem: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  emits: ['closeModal'],
  setup(props, { emit }) {
    const {
      toggleRef,
      onApplySampleItem,
      isSampleProduct,
      blockedSample,
      isEnabledApplyAction
    } = useSampleProduct(props.cartItem);

    const onApply = () => {
      onApplySampleItem();
      emit('closeModal');
    };

    return {
      toggleRef,
      onApply,
      onApplySampleItem,
      isEnabledApplyAction,
      isSampleProduct,
      blockedSample,
      closeCircleOutline
    };
  }
});
</script>
<style lang="scss" scoped>
.sticky-bottom-custom {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
}
.one-line-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  padding: 1.5px 0;
}
.rounded-md {
  --border-radius: 15px;
}
ion-toggle {
  height: 32px;
  width: 50px;
  --handle-background: #fff;
  --handle-background-checked: #fff;
  --background-checked: #00676a;
  --handle-width: 27px;
  --handle-height: 40px;
  --handle-border-radius: 16px;
  --handle-max-height: 28px;
  --handle-spacing: 2px;
  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
  contain: none;
}
</style>
