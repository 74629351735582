import { ACTIONS } from '@/modules/sale/store/cart/actions';
import { computed, nextTick, ref, watch } from 'vue';
import { useConfig, useGetStorageData } from '..';
import { useToast } from '../global';

export const useSampleProduct = (cartItem) => {
  const { id: cartItemId, is_booking_item, is_unavailable, is_out_of_stock, is_sample } = cartItem;

  const { t, storePath, store } = useConfig();
  const { selectedCompany, selectedUser } = useGetStorageData();
  const { showToast } = useToast();
  const isSampleProduct = ref(is_sample);
  const toggleRef = ref(null);
  const blockedSample = is_unavailable || is_out_of_stock;
  const isEnabledApplyAction = ref(false);
  const STORE_MODULE_PATH_CART = `${storePath}/cart`;
  const cartItems = computed(() => store.getters[`${STORE_MODULE_PATH_CART}/cart`]);

  const updateSampleItem = async () => {
    const { id: customerId } = await selectedCompany.value;
    const { id: userId } = await selectedUser.value;
    const {
      id: itemId,
      is_order_by_weight,
      total_available_quantity,
      order_weight,
      order_qty,
      selected
    } = cartItems.value.items.find((item) => item.id === cartItemId);
    await store.dispatch(`${STORE_MODULE_PATH_CART}/${ACTIONS.CHANGE_CART_ITEMS}`, {
      customerId,
      items: [
        {
          id: itemId,
          orderQuantity: !is_order_by_weight
            ? order_qty
            : total_available_quantity > 0 && order_weight > total_available_quantity
            ? Number(total_available_quantity)
            : Number(order_weight),
          orderWeight:
            total_available_quantity > 0 && order_weight > total_available_quantity
              ? total_available_quantity
              : order_weight,
          selected: selected,
          isSample: isSampleProduct.value
        }
      ]
    });

    await store.dispatch(`${STORE_MODULE_PATH_CART}/${ACTIONS.UPDATE_PRODUCT_SAMPLE_CART_ITEM}`, {
      userId,
      customerId
    });
  };

  const onApplySampleItem = async () => {
    if (blockedSample) {
      nextTick(() => {
        if (toggleRef.value) {
          toggleRef.value.$el.checked = false;
        }
      });
      return;
    }
    await updateSampleItem();
  };

  watch(isSampleProduct, async (newValue) => {
    if (is_booking_item && newValue) {
      await showToast(t('unable_booking_sample'));
      nextTick(() => {
        if (toggleRef.value) {
          toggleRef.value.$el.checked = false;
        }
      });
    }
    isEnabledApplyAction.value = true;
  });

  return {
    blockedSample,
    isEnabledApplyAction,
    toggleRef,
    onApplySampleItem,
    isSampleProduct
  };
};
